<template>
  <perseu-card :title="titleDialog">
    <template slot="content">
      <v-form ref="form" @submit.prevent="save">
        <v-text-field
          label="Idioma"
          v-model.trim="lang.name"
          :rules="[$rules.required]"
        />
      </v-form>
    </template>
    <template slot="actions">
      <v-btn @click="save" color="primary">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { save } from "@/services/langs-service";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleDialog() {
      return (this.lang.id ? "Editar " : "Novo ") + "Idioma";
    },
  },
  data: function () {
    return {
      lang: this.value,
    };
  },
  watch: {
    value(value) {
      this.lang = value;
    },
  },
  methods: {
    async save(event) {
      if (!this.$refs.form.validate()) return;
      try {
        let { data } = await save(this.lang);
        if (this.lang.id) {
          this.$emit("update", data);
        } else {
          this.$emit("create", data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
  },
};
</script>

<style></style>
